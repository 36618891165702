import React from "react";
import { Link } from "gatsby";
import TwitterLogo from "../../static/twitter-icon.svg";
import GithubLogo from "../../static/github-icon.svg";
import HackspaceLogo from "../../static/hackspace.svg";

import styled from "@emotion/styled";
import { css } from "@emotion/core";

const Footer = styled.footer`
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin: auto 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
  }

  .logo-hackspace{
    width: 100%;
    height: 100%;
  }
`;

const SvgLink = styled.a`
  svg {
    width: 30px;
    height: 30px; 
  }

  svg path {
    transition-property: fill;
    transition-duration: 0.3s;
  }

  svg:not(:hover) path {
    fill: rgba(0,0,0,0.3);
  }
`;

export default () => {
  return (
    <Footer className="Footer">
      <nav>
        <ul>
          <li>
            <SvgLink href="https://github.com/petercoulton">
              <GithubLogo className="logo-github" />
            </SvgLink>
          </li>
          <li>
            <SvgLink href="https://twitter.com/petercoulton">
              <TwitterLogo className="logo-twitter" />
            </SvgLink>
          </li>
          <li>
            <SvgLink href="https://teessidehackspace.org.uk">
              <HackspaceLogo className="logo-hackspace" width="40" height="40" />
            </SvgLink>
          </li>
        </ul>
      </nav>
    </Footer>
  )
};

