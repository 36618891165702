import React from "react";
import { Link } from "gatsby";

export default function Header() {
  return (
    <div className="Header">
      <div className="Header-title">
        <Link to="/">Peter Coulton</Link>
      </div>
      <nav>
        <ul className="Header-links">
          <li className="Header-link"><Link to="/#posts">Posts</Link></li>
        </ul>
      </nav>
    </div>
  )
};

