import React from "react"
import { Helmet } from "react-helmet"
import { MDXProvider } from "@mdx-js/react"

import "../styles/styles.scss";

import config from "../../data/SiteConfig";
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Layout({ children }) {
  return (
    <>
      <Helmet title="Peter Coulton">
        <meta name="description" content={config.siteDescription} />
        <html lang="en-GB" />
      </Helmet>

      <Header />

      <main role="main">
        <MDXProvider>{children}</MDXProvider>
      </main>

      <Footer />
    </>
  )
};
